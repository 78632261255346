import React from "react"
import ReactDOM from 'react-dom'
import Img from 'gatsby-image'
import Portal from './Portal'

class ImageModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active: false
        }
        this.setActive = this.setActive.bind(this)
        this.setInactive = this.setInactive.bind(this)
        this.modal = (<div></div>)
    }

    setActive() {
        this.setState({active: true})
        console.log('setActive');
    }
    setInactive() {
        this.setState({active: false})
        console.log('setInactive');
}

    render() {
        this.modal = (
                // <div className={`modal ${this.state.active ? 'is-active': ''}`}>
                <div>
                <div className={"modal"+` ${this.state.active ? 'is-active' : ''}`}>
                    {/* {console.log(this.state)} */}
                    <div onClick={this.setInactive} className="modal-background"></div>
                    <div className="modal-content">
                        <Img  {...this.props} />
                    </div>
                    <button onClick={this.setInactive} className="modal-close is-large" aria-label="close"></button>
                </div>
                </div>
        )
        return (
            < >
            <div onClick={this.setActive}>
                <Img  {...this.props} />
            </div>
            <Portal>
                {this.modal}
            </Portal>
            </>
        )
    } 
}

export default ImageModal